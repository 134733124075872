import { Link } from 'react-router-dom';
import '../styles/Blog.css';

function BlogBox(props){
    const Wrapper = props.isExternalLink ? 'a' : Link;

    function getDomain(url, subdomain) {
        subdomain = subdomain || false;
    
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');
    
        if (!subdomain) {
            url = url.split('.');
    
            url = url.slice(url.length - 2).join('.');
        }
    
        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }
    
        return url;
    }

    return(
        <li className="blog-list-wrapper">
        <Wrapper 
            className='blog-box'
            href={ props.isExternalLink ? props.link : undefined }
            target={ props.isExternalLink ? '_blank' : undefined }
            rel={ props.isExternalLink ? 'noreferrer' : undefined }
            to={ !props.isExternalLink ? props.link : undefined }
        >
            {
                props.preview ?
                <img className='blog-box-preview' src={ props.preview } />
                :
                <img className='blog-box-preview' src="/ryon-profile-neg-big.png" />
            }
            
            <div className='blog-box-text'>
                <h4>
                    { props.title }
                    { props.isExternalLink ? <i className="fa-solid fa-arrow-up-right-from-square fa-xs" /> : undefined }
                </h4>
                <p className='blog-box-desc'>{ props.desc }</p>
                <div className='blog-box-info'>
                <div className='blog-box-author'>{ props.author }</div>
                { props.date ? <div className='blog-box-date'>{ props.date }</div> : undefined}
                { props.isExternalLink ? <div className='blog-box-url'>{ getDomain(props.link) }</div> : undefined}
                </div>
            </div>
        </Wrapper>
        </li>
    );
}

export default BlogBox;