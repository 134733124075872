import Section from "../components/Section";
import Footer from "../components/Footer";

import TempImg1 from '../assets/images/i-earn-gold-1.jpg';

import '../styles/IEarnGold.css';

function IEarnGold(){
    return(
        <div className='main-wrapper'>
        <main style={{paddingTop:'50px'}}>
            <div className="i-earn-gold-container">
                <div className="i-earn-gold-over">
                <h3>We Are Coming Soon</h3>
                </div>
                <img src={TempImg1} alt=""></img>
            </div>
        </main>
        <Footer />
        </div>
    );
}
{/*
<a href="https://unsplash.com/ko/%EC%82%AC%EC%A7%84/%EB%82%AE%EC%97%90%EB%8A%94-%ED%9A%8C%EC%83%89-%EB%8F%84%EB%A1%9C%EC%9D%98-%EB%85%B8%EB%9E%80%EC%83%89-%ED%8A%B8%EB%9F%AD-1HEDPbH6HIE?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a><span>의</span><a href="https://unsplash.com/ko/@dominik_photography?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Dominik Vanyi</a>
*/}



export default IEarnGold;