import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import HeaderAnimation from '../components/HeaderAnimation';
import Section from '../components/Section';
import Footer from '../components/Footer';

import '../styles/Header.css'

import { ReactComponent as ExternalLinkIcon} from '../assets/images/external-link.svg';
import IntroDesc5Chart from '../components/IntroDesc5Chart';
import ImageBack1 from '../assets/images/img-back-1.png';
import ImageBack2 from '../assets/images/img-back-2.png';
import ImageBack3 from '../assets/images/img-back-3.png';
import ImageBack4 from '../assets/images/img-back-4.png';

function Introduction() {
    const [introAnimationFirst, setIntroAnimationFirst] = useState(false);
    const [introAnimationSecond, setIntroAnimationSecond] = useState(false);
    const [introAnimationThird, setIntroAnimationThird] = useState(false);
    const [introAnimationFourth, setIntroAnimationFourth] = useState(false);
    
    useEffect(() => {
        //페이지 로딩 후 실행
        setIntroAnimationFirst(true);
    }, []);

    return(
    <div className='main-wrapper'>
    <header className="header">
        <TransitionGroup>
        <div className="header-bg"><HeaderAnimation /></div>
        
        <div className="quote">
        <CSSTransition in={introAnimationFirst} timeout={1000} classNames="quotes" mountOnEnter unmountOnExit onEntered={() => setIntroAnimationSecond(true)}>
            <span className="quote-1">RYON is  </span>
        </CSSTransition><br />
        
        <CSSTransition in={introAnimationSecond} timeout={1000} classNames="quotes" mountOnEnter unmountOnExit onEntered={() => setIntroAnimationThird(true)}>
            <span className="quote-2">Monetizing Gold as</span>
        </CSSTransition><br />
        <CSSTransition in={introAnimationThird} timeout={1000} classNames="quotes" mountOnEnter unmountOnExit onEntered={() => setIntroAnimationFourth(true)}>
            <span className="quote-3">an Income-Generating Asset</span>
        </CSSTransition>
        
        <CSSTransition in={introAnimationFourth} timeout={1000} classNames="header-button-anim" mountOnEnter unmountOnExit>
        <div>
            <div className='header-desc'>
            Ryon introduces a new gold investment model that addresses the shortcoming of traditional gold investment by issuing tokens backed by physical gold. This innovative approach provides investors with cash flow, opening up new investment opportunities.
            </div>
            {/*
            <div className="header-button">Connect<ExternalLinkIcon fill='white' /></div>
            */}
        </div>
        </CSSTransition>
        </div>
        </TransitionGroup>
        
    </header>
    <main>
        <Section title="RYON ASSET">
            <p className='sect-side-space'>
            <h4>Challenges Facing the Traditional Gold Investment Market</h4>
            <hr/>
            Traditional gold investments have been less attractive due to their lack of cash flow. While advancements in financial products have addressed physical storage concerns, gold itself does not provide dividends or interest income. In contrast, equities offer investors stable returns through dividends and capital gains, making them a more appealing investment option.
            <br/><br/>
            Additionally, the global gold market experiences real-time price discrepancies across different countries due to various factors such as regional demand differences, exchange rate fluctuations, and fees. This results in asymmetry in price information for international trades, leading to higher transaction costs and undermining market transparency and efficiency.
            <br/><br/>
            Ryon introduces a new gold investment model that addresses the shortcoming of traditional gold investments by issuing tokens backed by physical gold. This innovative approach provides investors with cash flow, opening up new investment opportunities.
            </p>
        </Section>
        {/*
        <Section title="Our Solution">
        <p className='sect-side-space' >
            <h4>Utilizing traditional financial infrastructure to tokenize gold, and monetizing gold as an income-generating asset via gold token exchanges.</h4>
            <hr/>
            <div className='sect-box-wrapper'>
                <div className='sect-box sect-box-half' style={{ backgroundImage: `url(${ImageBack1})`, backgroundSize:'30% auto', backgroundRepeat:'no-repeat', backgroundPosition:'bottom right'}}>
                <h4>Tokenization Of Gold-Backed ETFs</h4>
                    <p>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> Tokenization of Gold ETFs : Converting physical gold-backed ETFs into digital tokens.
                    <br/><br/>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> Leveraging Traditional Financial Systems : Utilizing existing transparent verification systems for gold ETFs to ensure token reliability.
                    </p>
                </div>
                <div className='sect-box sect-box-half' style={{ backgroundImage: `url(${ImageBack2})`, backgroundSize:'30% auto', backgroundRepeat:'no-repeat', backgroundPosition:'bottom right'}}>
                <h4>Standardization For Global Trading</h4>
                    <p>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> Standardization of gold token trading units (1 ounce) based on gold ETFs
                    <br/><br/>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> Integration of gold tokens issued on various STO platforms into a unified mainnet.
                    </p>
                </div>
                <div className='sect-box sect-box-half' style={{ backgroundImage: `url(${ImageBack3})`, backgroundSize:'30% auto', backgroundRepeat:'no-repeat', backgroundPosition:'bottom right'}}>
                <h4>Global Gold Token Exchange</h4>
                    <p>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> Global real-time physical gold-backed token decentralized exchange (DEX)
                    <br/><br/>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> 24/7 AMM swap pool
                    <br/><br/>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> Globally synchronized gold token pricing
                    </p>
                </div>
                <div className='sect-box sect-box-half' style={{ backgroundImage: `url(${ImageBack4})`, backgroundSize:'30% auto', backgroundRepeat:'no-repeat', backgroundPosition:'bottom right'}}>
                <h4>Revenue Generating Gold Token</h4>
                    <p>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> Ryon is launching I-EARN GOLD, a revenue-generating gold token that bridges traditional finance and blockchain.
                    <br/><br/>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> Investors can earn revenue simply by holding the Ryon-Vault investment token I-EARN GOLD 
                    <br/><br/>
                    <i className="fa-solid fa-circle-dot fa-2xs" /> It means a novel redefinition of gold as a financial product

                    <br/>
                    </p>
                </div>
            </div>
        </p>
        </Section>
        */}
        <Section title="Tokenize Gold ETF">
        <p className='sect-side-space' >
            <h4>Utilizing traditional financial infrastructure to tokenize gold, and monetizing gold as an income-generating asset via gold token exchanges.</h4>
            <hr/>
            <div className='sect-box-wrapper'>
            <a id="ssga-link" className='sect-box-link sect-box sect-box-half' href="https://www.ssga.com/international/en/institutional/ic/about-us/who-we-are" target="_blank" rel="noreferrer">
                <h4>SDPR</h4>
                <p>
                by STATE STREET GLOBAL ADVISORS
                <br/><br/>
                Learn More <i className="fa-solid fa-arrow-right-long fa-2xs" />
                </p>
            </a>
            <a id="invesco-link" className='sect-box-link sect-box sect-box-half' href="https://www.invesco.com/uk/en/about-us.html" target="_blank" rel="noreferrer">
                <h4>Ishare</h4>
                <p>
                by Invesco
                <br/><br/>
                Learn More <i className="fa-solid fa-arrow-right-long fa-2xs" />
                </p>
            </a>
            </div>
        </p>
        </Section>
        <Section title="Our Advantage">
        <p className='sect-side-space' >
            <h4>I-EARN GOLD is a gold-backed token (Real Yield On Chain Asset) that offers inflation-protected real returns.</h4>
            <hr/>
            <div className='sect-box-wrapper'>
                <div className='sect-box sect-box-half'>
                <h4>Solving the problems of storage cost and proof of reserve</h4>
                    <p>
                    Leveraging the infrastructure reliability of traditional financial systems to reduce the storage costs or proof of storage costs of real-world asset-backed tokens (RWAs).
                    </p>
                </div>
                <div className='sect-box sect-box-half'>
                <h4>Yield-generating gold tokens</h4>
                    <p>
                    It generates additional returns from gold investments by providing passive income through gold token liquidity provision (Reward Bearing Model).
                    </p>
                </div>
                <div className='sect-box sect-box-half'>
                <h4>Worldwide real-time gold token transactions</h4>
                    <p>
                    Ryon's standardized gold token units and unified mainnet enable real-time global trading of physical gold-backed assets. Arbitrage opportunities stemming from discrepancies in national gold demand and exchange rates enhance market transparency and efficiency.
                    </p>
                </div>
                <div className='sect-box sect-box-half'>
                <h4>Gold ETFs offer abundant liquidity and high scalability.</h4>
                    <p>
                    As of May 2024, global physically-backed gold ETFs had exceeded $220 billion in AUM, providing abundant liquidity. Ryon Gold Tokens offer high scalability, enabling issuance in any country where gold-backed ETFs are listed.
                    </p>
                </div>
            </div>
            {/* 
            <a className='sect-box-sto' href="https://www.stosummit.org/" target='_blank' rel="noreferrer">
            <span> Further information will be presented at the STO Summit 2024 Tokyo <i className="fa-solid fa-link" /></span>
            </a>
            */}
        </p>
        </Section>
        <section className="community">
        <p>Community</p>
        <ul className='community-list'>
            <li className='community-button'><a href="https://x.com/RyonAsset" target='_blank' rel="noreferrer"><img src="./images/x-logo.svg" /></a></li>
            <li className='community-button'><a href="https://t.me/ryon_asset" target='_blank' rel="noreferrer"><img src="./images/telegram-logo.svg" /></a></li>
            <li className='community-button'><a href="https://www.linkedin.com/company/ryon-asset/" target='_blank' rel="noreferrer"><img src="./images/linked-in-logo.svg" /></a></li>
            {/*
            <li className='community-button'><img src="./images/discord-logo.svg" /></li>
            <li className='community-button'><img src="./images/youtube-logo.svg" /></li>
            <li className='community-button'><img src="./images/reddit-logo.svg" /></li>
            */}
        </ul>
        </section>
    </main>
    <Footer />
    </div>
    );
}
export default Introduction;