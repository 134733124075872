import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);

function IntroDesc5Chart(){
    const lineData = {
        labels: ["Jul '23", "Sep '23", "Nov '23", "Jan '24", "Mar '24", "May '24", "Jul '24"],
        datasets: [
            {
                label: 'sUSDe',
                backgroundColor: 'rgb(50,50,150)',
                pointStyle:false,
                fill: {
                    target: 'origin',
                    above: 'rgba(0,0,100,0.5)'
                },
                tension: 0.5,
                borderWidth: 0,
                data: [0, 0.01, 0.13, 0.2, 0.3, 0.4, 2],
            },
            {
                label: 'sDAI',
                backgroundColor: 'rgb(250,70,120)',
                pointStyle:false,
                fill: {
                    target: 'origin',
                    above: 'rgba(200,20,50,0.5)'
                },
                tension: 0.5,
                borderWidth: 0,
                data: [0, 0.8, 1.3, 1.2, 1.3, 1.8, 2.8],
            },
            {
                label: 'sDAI',
                backgroundColor: 'rgb(50,150,120)',
                pointStyle:false,
                fill: {
                    target: 'origin',
                    above: 'rgba(0,100,80,0.5)'
                },
                tension: 0.5,
                borderWidth: 0,
                data: [0, 0.8, 1.3, 1.2, 1.3, 2.1, 3.2],
            }
        ]
    }

    return(
        <>
        <div style={{textAlign:'center', fontSize: '20px', fontWeight:'700', color:'#232882', margin:'10px 0'}}>
            Daily USD Yield-Bearing Tokens Marketcap
        </div>
        <Line type="line" data={lineData}></Line>
        </>
    );
}

export default IntroDesc5Chart;