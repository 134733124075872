import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { throttle } from 'lodash';

import '../styles/Nav.css';

import { ReactComponent as NavBtnIcon} from '../assets/images/nav-menu.svg';

function Nav(){
    const [ navClasses, setNavClasses ] = useState("nav nav-white");
    const [ displaySideMenu, setDisplaySideMenu ] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return() => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    const linkClick = (event) => {
        const eventTarget = event.currentTarget.getAttribute("href");
        
        
        if(eventTarget !== location.pathname){
            setDisplaySideMenu(false);
        }
    };
    
    function toggleSideMenu() {
        setDisplaySideMenu(!displaySideMenu);
    }

    const handleResize = throttle(() => {
        if( window.innerWidth >= 768 ) {
            setDisplaySideMenu(false);
        }
    }, 200);

    return(
        <>
        <nav className={navClasses}>
            <div className="nav-logo">
            <Link to="/" onClick={linkClick}>
            <img src="./ryon-asset-positive.png" alt="RYON ASSET" />
            </Link>
            </div>
            
            <ul>
            <Link to="/" onClick={linkClick}>
            <li className="nav-button"><span>INTRO</span><div className="nav-button-bar" /></li>
            </Link>
            <Link to="/i-earn-gold" onClick={linkClick}>
            <li className="nav-button"><span>I-EARN GOLD</span><div className="nav-button-bar" /></li>
            </Link>
            <Link to="/blog" onClick={linkClick}>
            <li className="nav-button"><span>BLOG</span><div className="nav-button-bar" /></li>
            </Link>
            <Link to="/about" onClick={linkClick}>
            <li className="nav-button"><span>ABOUT US</span><div className="nav-button-bar" /></li>
            </Link>
            </ul>
            <div className='nav-menu-button' onClick={toggleSideMenu}><NavBtnIcon /></div>
        </nav>
        <div className={'side-menu-backdrop' + (displaySideMenu ? ' side-menu-backdrop-show' : '')} onClick={toggleSideMenu} />
        <div className={'side-menu' + (displaySideMenu ? ' side-menu-show' : '')}>
            <Link to="/" onClick={linkClick}>
                <span>INTRO</span>
            </Link>
            <Link to="/i-earn-gold" onClick={linkClick}>
                <span>I-EARN GOLD</span>
            </Link>
            <Link to="/blog" onClick={linkClick}>
                <span>BLOG</span>
            </Link>
            <Link to="/about" onClick={linkClick}>
                <span>ABOUT US</span>
            </Link>
        </div>
        </>
    );
}

export default Nav;