import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import '../styles/ContactUs.css';

export const ContactUs = () => {
    const form = useRef();
    
        const sendEmail = (e) => {
        e.preventDefault();

        // Get form data
        const name = form.current.user_name.value;
        const email = form.current.user_email.value;
        const message = form.current.message.value;
        
        // Check if any field is empty
        if (!name || !email || !message) {
            alert('Please fill in all fields before sending the message.');
            return; // Stop form submission
        }

        emailjs
            .sendForm('service_y7q8das', 'template_o3p475h', form.current, {
            publicKey: 'DIpmGwG2Wzefly1rf',
            })
            .then(
            () => {
                alert('Your message has been successfully sent!');
            },
            (error) => {
                console.log("Oops! Something went wrong and your message couldn't be sent. Please try again later. Error:", error.text);
            },
            );
        };
    
    return (
        <form className='contact-us-container' ref={form} onSubmit={sendEmail}>
            <label className='contact-us-title'>Name</label>
            <input className='contact-us-input' type="text" name="user_name" placeholder='John Doe' />
            <label className='contact-us-title' >Email</label>
            <input className='contact-us-input' type="email" name="user_email" placeholder='example@email.com' />
            <label className='contact-us-title'>Message</label>
            <textarea className='contact-us-input' name="message" placeholder='Write your message here' />
            <input className='contact-us-send' type="submit" value="Submit"></input>
        </form>
    );
};

export default ContactUs;