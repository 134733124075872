function Member(props) {
    return(
        <li className="emp-wrapper">
            <img className="emp-picture" src={ props.img } alt="Member"/>
            <div className="emp-text">
            <p className='emp-name'>{ props.name }</p>
            <p className='emp-position'>{ props.pos }</p>
            </div>
        </li>
    );
}

export default Member;