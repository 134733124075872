import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Routes, Route, Navigate, useLocation, } from 'react-router-dom';
import { useScroll } from 'framer-motion';

import './App.css';
import './styles/Animations.css';

import Introduction from './pages/Introduction';
import IEarnGold from './pages/IEarnGold';
import Blog from './pages/Blog';
import About from './pages/About';
import Footer from './components/Footer';
import Nav from './components/Nav';

function App() {
  const { scrollY } = useScroll();
  const location = useLocation();

  useEffect(() => {
    //페이지 로딩 후 실행
    console.log("[DEBUG] Page Loaded");
    return() => {}
  }, []);


  return (
    <div className="app">
      <Nav />
      <TransitionGroup className={"main-content"}>
      <CSSTransition key={location.pathname} timeout={700} classNames="ryon-page-transition">
        <Routes location={location}>
          <Route path="/" exact element={<Introduction />} />
          <Route path="/i-earn-gold" exact element={<IEarnGold />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="*" exact element={<Navigate to="/" />} />
        </Routes>
      </CSSTransition>
      </TransitionGroup>

    </div>
    
  );
}

export default App;
