import Section from "../components/Section";
import Footer from "../components/Footer";
import Member from '../components/Member';

import '../styles/Employee.css'

import MemberAugustin from '../assets/images/img-member-augustin.png';
import MemberSimon from '../assets/images/img-member-simon.png';
import MemberEugene from '../assets/images/img-member-eugene.png';
import MemberMinsung from '../assets/images/img-member-minsung.png';
import MemberYongchan from '../assets/images/img-member-yongchan.png';
import MemberJackie from '../assets/images/img-member-jackie.png';

import LogoEnvisager from '../assets/images/envisager_logo.png';
import LogoIsta from '../assets/images/ista_logo.png';
import ContactUs from "../components/ContactUs";

function About(){


    return(
        <div className='main-wrapper'>
        <main style={{paddingTop:'100px'}}>            
            <Section title="Our Team" detailClass="emp-sect-container">
            <p className="sect-side-space">
                <h4>Teammate</h4>
                <ul className="emp-container">
                <Member name="Simon" pos="CSO" img={ MemberSimon } />
                <Member name="TBA" pos="CTO" img="./images/team-no-image.png" />
                <Member name="Eugene" pos="Co-Founder" img={ MemberEugene } />
                <Member name="Jackie" pos="Co-Founder" img={ MemberJackie } />
                <Member name="Minsung" pos="CIO" img={ MemberMinsung } />
                <Member name="Hugh" pos="Front-End Engineer" img="./images/team-no-image.png" />
                </ul>
                <h4>Advisor</h4>
                <ul className="emp-container">
                <Member name="Augustin" pos="Partnership" img={ MemberAugustin } />
                <Member name="Yongchan" pos="Blockchain" img={ MemberYongchan } />
                <Member name="Kangwook" pos="Legal" img="./images/team-no-image.png" />
                </ul>
            </p>
            </Section>
            <Section title="Partner">
            <p className='sect-side-space'>
            <div className='partner'>
                <div className='partner-image-helper'></div>
                <a href="https://www.envisager.kr/" target='_blank' rel="noreferrer">
                    <img src={LogoEnvisager} className="partner-image" alt="ENVISAGER" />
                </a>
                <a href="https://www.ista.online/" target='_blank' rel="noreferrer">
                    <img id="partner-ista-logo" src={LogoIsta} className="partner-image" alt="ISTA" />
                </a>
            </div>
            </p>
        </Section>
        <Section title="Contact Us">
        <p className="sect-side-space">
        <h4>Connect with us – we're just a message away.</h4>
        <hr/>
        <ContactUs />
        </p>
        </Section>
        </main>

        <Footer />
        </div>
    );
}

export default About;