import Section from "../components/Section";
import Footer from "../components/Footer";
import BlogBox from '../components/BlogBox';

import '../styles/Blog.css';

import BlogImgStoSummit from '../assets/images/blog-sto-summit.png';
import BlogRyonAssetFounder from '../assets/images/blog-ryon-asset-founder.png';

function Blog(){
    return(
        <div className='main-wrapper'>
        <main style={{paddingTop:'100px'}}>
            <Section title="Blog">
            <p className="sect-side-space">
            <ul className="blog-list-container">
                <BlogBox
                    title="STO SUMMIT Eugene Yoon Introduce"
                    desc="Ryon Asset's Gold Tokenization and Trading Ecosystem Activation Strategy"
                    author="STO SUMMIT"
                    isExternalLink={true}
                    link="https://www.stosummit.org/blank-11"
                    preview={BlogImgStoSummit}
                />
                <BlogBox
                    title="RYON ASSET Founder Eugene Yoon: Revolutionizing Gold Investment"
                    desc="Eugene Yoon, a seasoned certified public accountant (CPA) with over two decades of experience in traditional financial markets, has emerged as a driving force behind RYON ASSET."
                    author="ISTA HQ"
                    date="JUL 2, 2024"
                    isExternalLink={true}
                    link="https://www.linkedin.com/pulse/ryon-asset-founder-eugene-yoon-revolutionizing-gold-investment-jiang-h2zhc/"
                    preview={ BlogRyonAssetFounder }
                />
            </ul>
            </p>
            </Section>
        </main>
        <Footer />
        </div>
    );
}

export default Blog;